import {getAwayGoal, getHomeGoal,getHalfTimeAwayGoal, getHalfTimeHomeGoal} from "../functions";
import React from "react";

const Scores = ({scores, totalHalfTimeOdds,period}) => {
  return <>
    <span className="text-warning">
      {getHomeGoal(scores)}:{getAwayGoal(scores)}
    </span>
    <br/>
    {(totalHalfTimeOdds>0&&period<2) ? (<span>HT</span>): (period>2)?   <>({getHalfTimeHomeGoal(scores)}:{getHalfTimeAwayGoal(scores)})</>:<></>}
  </>
};

export default Scores;
