import {FaPause, FaPlay} from "react-icons/fa";
import React from "react";

const Period = (period) => {

  return (period.period === 2 ||period.period === 4) ? (
    <FaPause style={{color: '#d696b6', fontSize: '25px'}}/>
  ) : (
    <FaPlay style={{color: '#61dafb', fontSize: '23px'}}/>
  );
};

export default Period;
