import {io} from 'socket.io-client';
import {liveGamesStorage} from './rxjs-stores';

const onConnect = () => {
  console.log('connected to socket');
};

const onExpiredGamesEvent = (payload) => {
  if (!payload) return;
  try {
    if (payload.indexOf('live_game_') !== -1)
      liveGamesStorage.removeEvent(payload.replace('live_game_', ''));
  } catch (err) {
    console.log('error parsing json from server');
  }
};

/**
 * a game is received on a channel
 * @param game
 */
const addGameReceivedEvent = (game) => {
  if (!game) return;
  if (game.odds?.length === 0) return;

  const { league } = game;
  if (league && league.indexOf('Esoccer') > -1) {
    return;
  }
  try {
    liveGamesStorage.addOrUpdate(game);
  } catch (err) {
    console.log('error parsing json from server');
  }
};

const connect = (() => {
  let socket;
  /**
   * returns a setup function,
   * closes over the socket
   */
  return () => {
    if (socket) return socket;
    //initialize if not already initialized
   socket = io('wss://socket.smbet.info');
    //socket = io('http://localhost:4001');
    socket.on('connect', onConnect);
    socket.on('expired-key', onExpiredGamesEvent);
    socket.on('live-feeds-event', addGameReceivedEvent); // receive live feeds updates
    socket.on('live-games', addGameReceivedEvent); // receive all live games when connected
    return socket;
  };
})();

export default connect;
