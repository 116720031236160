import {
  getAwayGoal,
  getHalfTimeOddsMatchResult,
  getHomeGoal,getHomeRedCards,getAwayRedCards
} from '../functions';
import BookingCards from './BookingCards';
import {
  GoalGoal,
  MatchResult,
  NextGoal,
  OverUnder,
  Period,
  Scores,
  Time,
} from './index';
import React from 'react';
const getGoalCss = (() => {
  const base = 'goalCss';
  return () =>
    [
      base   
    ]
  
})();
const getDangerCss = (() => {
  const base = 'dangerCss';
  return () =>
    [
      base   
    ]
  
})();


const GridRow = ({ game }) => {
  // console.log(game);
  const {
    awayTeam,
    homeTeam,
    matchId,
    matchTime,
    odds,
    scores,
    blocked,
    shortCode,
    period,
    eventStateId,
    eventState
  }= game;


  const { length: totalHalfTimeOdds } = getHalfTimeOddsMatchResult(odds);
  const totalGoals = getHomeGoal(scores) + getAwayGoal(scores);
  const totalAwayRedcards=getAwayRedCards(scores);
  const totalHomeRedcards=getHomeRedCards(scores);
  return (
   ( odds!=null&& odds.length)>0?
    <tr key={matchId} data-match-time={matchTime}  >
      <td>{shortCode}</td>
      <td>
        <Period period={period} />
      </td>
      <td>
        <Time period={period} matchTime={matchTime} />
      </td>
      <td>
        <span>{homeTeam} <BookingCards cardType="RED_CARD" totalCards={ totalHomeRedcards }/></span>
        <br />
        <span>{awayTeam} <BookingCards cardType="RED_CARD" totalCards={totalAwayRedcards}/></span>
      </td>
      <td className='text-center'>
        <Scores scores={scores} totalHalfTimeOdds={totalHalfTimeOdds}  period={period} />
      </td>
      {(eventStateId==="11003" || eventStateId==="21003")?<td className={getGoalCss()} colSpan="11">{eventState}</td>:
      (eventStateId==="11008" || eventStateId==="21008")?<td className={getDangerCss()} colSpan="11">{eventState}</td>
      :<><MatchResult blocked={blocked} odds={odds} />
      <OverUnder blocked={blocked} odds={odds} />
      <GoalGoal blocked={blocked} odds={odds} />
      <NextGoal blocked={blocked} odds={odds} totalGoals={totalGoals} />
      </>
   }
      
    </tr>:<td></td>
  );
};

export default GridRow;
