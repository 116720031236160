import React from "react";
import logo from '../red-card.png';
const BookingCards= ({cardType, totalCards}) => {

 return<>
  {(cardType=="RED_CARD"&&totalCards>0) ? <span className="text-danger">
 <img src={logo} alt="Logo" width={15} height={15}/>
    </span>
    : cardType=="YELLOW_CARD"?  <span className="text-warning">
{totalCards} <img alt="" src="../red-card.png" />
  </span> :''
}
</>
} 

export default BookingCards;
