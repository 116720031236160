import {Grid} from './components';
import {useSocketIO} from './hooks';

function App() {
  useSocketIO();
  return (
    <div className="App">
      <Grid />
    </div>
  );
}

export default App;
